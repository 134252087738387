import React from 'react'
import './about.css'
import { FaReact } from 'react-icons/fa'
import { FaJava } from 'react-icons/fa'
import { useState } from 'react'
import { GiBrain } from 'react-icons/gi'
import { BsPatchCheckFill } from 'react-icons/bs'

const About = ({ about, skills }) => {
  const [content, setContent] = useState('');

  const display = () => {
    if(content === 'Languages'){
      return (
        <div className='row' onClick={() => setContent('')}>
          <div className='col-10 offset-1'>
            <div className='row about__card p-1 mx-1'>
              <FaJava className='col-12 my-lg-4 my-3 about__icon' />
              <h3 className='fnt-3 col-12 my-lg-4 my-3 text-center text-light'>Programming Languages</h3>
              {skills.programming_languages.map((language) =>
              <div key={language} className='about-skills my-1 col-4 text-info text-start'>
                <BsPatchCheckFill className='text-light'/> {language}
              </div>
              )}
            </div>
          </div>
        </div>
      )
    }
    else if(content === 'Frameworks'){
      return (
        <div className='row' onClick={() => setContent('')}>
          <div className='col-10 offset-1'>
            <div className='about__card row p-1 mx-1'>
              <FaReact className='col-12 about__icon my-lg-4 my-3' />
              <h3 className='fnt-3 text-light col-12 my-lg-4 my-3'>Frameworks / Dev Tools</h3>
              {skills.frameworks.map((language) =>
                <div key={language} className='about-skills my-1 col-6 text-info text-start'>
                  <BsPatchCheckFill className='text-light'/> {language}
                </div>
              )}
            </div>
          </div>
        </div>
      )
    }
    else if(content === 'Other'){
      return (
        <div className='row' onClick={() => setContent('')}>
          <div className='col-10 offset-1'>
            <div className='about__card row p-1 mx-1'>
              <GiBrain className='col-12 about__icon my-lg-4 my-3' />
              <h3 className='fnt-3 col-12 text-light my-lg-4 my-3'>Skills / Concepts</h3>
              {skills.concepts.map((language) =>
              <div key={language} className='about-skills my-1 col-6 text-info text-start'>
                <BsPatchCheckFill className='text-light'/> {language}
              </div>
              )}
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <section id="about">
      <h2>About Me</h2>

      <div className='container my-4'>
        <div className='row'>
          <p className='col fnt-3 text-center text-light mx-2'>
            {about}
          </p>
        </div>
      </div>
      <div className="container">
        {content === '' ? 
        <div className='row'>

          <div className='col-4' onClick={() => setContent('Languages')}>
            <div className='row about__card p-1 mx-1 h-100'>
              <FaJava className='col-12 my-md-4 my-2 about__icon' />
              <h3 className='fnt-3 col-12 my-md-4 my-2 text-center text-light'>Programming Languages</h3>
            </div>
          </div>

          <div className='col-4' onClick={() => setContent('Frameworks')}>
            <div className='about__card row p-1 mx-1 h-100'>
              <FaReact className='col-12 about__icon my-md-4 my-2' />
              <h3 className='fnt-3 text-light col-12 my-md-4 my-2'>Frameworks / Dev Tools</h3>
            </div>
          </div>

          <div className='col-4' onClick={() => setContent('Other')}>
            <div className='about__card row p-1 mx-1 h-100'>
              <GiBrain className='col-12 about__icon my-md-4 my-2' />
              <h3 className='fnt-3 col-12 text-light my-md-4 my-2'>Skills / Concepts</h3>
            </div>
          </div>

        </div>
        :
        display()
        }
      </div>
    </section>
  )
}

export default About